import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        bg1: file(relativePath: { eq: "background2.jpg" }) {
          childImageSharp {
             fluid(maxWidth: 1920, maxHeight: 1050, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bg2: file(relativePath: { eq: "background3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1050, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        p1: file(relativePath: { eq: "portfolio/p1.png" }) {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 960, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        img20181: file(relativePath: { eq: "studio/year18/1.png" }) {
          childImageSharp {
            fluid(maxWidth: 830, maxHeight: 960, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }


        img20182: file(relativePath: { eq: "studio/year18/2.png" }) {
          childImageSharp {
            fluid(maxWidth: 642, maxHeight: 960, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        img20183: file(relativePath: { eq: "studio/year18/3.png" }) {
          childImageSharp {
            fluid(maxWidth: 594, maxHeight: 420, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        img20184: file(relativePath: { eq: "studio/year18/4.png" }) {
          childImageSharp {
            fluid(maxWidth: 424, maxHeight: 420, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        img20185: file(relativePath: { eq: "studio/year18/5.png" }) {
          childImageSharp {
            fluid(maxWidth: 441, maxHeight: 420, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        img20186: file(relativePath: { eq: "studio/year18/6.png" }) {
          childImageSharp {
            fluid(maxWidth: 590, maxHeight: 420, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

  
        w1: file(relativePath: { eq: "works/1.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w2: file(relativePath: { eq: "works/2.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w3: file(relativePath: { eq: "works/3.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w4: file(relativePath: { eq: "works/4.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w5: file(relativePath: { eq: "works/5.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w6: file(relativePath: { eq: "works/6.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w7: file(relativePath: { eq: "works/7.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w8: file(relativePath: { eq: "works/8.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w9: file(relativePath: { eq: "works/9.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        w10: file(relativePath: { eq: "works/10.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w11: file(relativePath: { eq: "works/11.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w12: file(relativePath: { eq: "works/12.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w13: file(relativePath: { eq: "works/13.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w14: file(relativePath: { eq: "works/14.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w15: file(relativePath: { eq: "works/15.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w16: file(relativePath: { eq: "works/16.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w17: file(relativePath: { eq: "works/17.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        w18: file(relativePath: { eq: "works/18.jpg" }) {
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

      background: file(relativePath: { eq: "studio/Homepage-background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        arsen1: file(relativePath: { eq: "Arsen-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 560, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        st191: file(relativePath: { eq: "studio/year19/21.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        st192: file(relativePath: { eq: "studio/year19/20.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        st181: file(relativePath: { eq: "studio/year18/30.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        st182: file(relativePath: { eq: "studio/year18/9.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        st171: file(relativePath: { eq: "studio/year17/17.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        st172: file(relativePath: { eq: "studio/year17/34.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        st161: file(relativePath: { eq: "studio/year16/31.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        st162: file(relativePath: { eq: "studio/year16/35.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        
        st5: file(relativePath: { eq: "studio/5.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        
        st151: file(relativePath: { eq: "studio/year15/2015.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        st152: file(relativePath: { eq: "studio/year15/9.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data[props.image].childImageSharp.fluid} />}
  />
)
export default Image
